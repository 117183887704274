import React, { cloneElement } from 'react';

import { useBi, useEnvironment } from '@wix/yoshi-flow-editor';
import type { RawParams, HrefOptions } from '@uirouter/core';

import { useHref, useRouter } from './hooks';

interface IUISrefProps {
  state: string;
  params?: RawParams;
  options?: HrefOptions;
  disabled?: boolean;
  bi?: any;
  children: React.ReactElement;
}

export function UISref(props: IUISrefProps) {
  const { state, params, options, disabled, children, ...rest } = props;
  const { isViewer } = useEnvironment();
  const bi = useBi();

  const router = useRouter();
  const href = useHref(state, params, options);

  if (disabled) {
    return cloneElement(children, rest);
  }

  return cloneElement(children, {
    href: isViewer ? href : undefined,
    onClick: handleClick,
    ...children.props,
    ...rest,
  });

  function handleClick(event: React.MouseEvent) {
    if (props.bi) {
      bi.report(props.bi);
    }

    if (isViewer) {
      handleViewerNavigation(event);
    }
  }

  function handleViewerNavigation(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    router.go(state, params, options);
  }
}

UISref.defaultProps = {
  options: {
    absolute: true,
  },
};

UISref.displayName = 'UISref';
