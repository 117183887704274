import type { Experiments } from '@wix/yoshi-flow-editor';

export function getKey(
  experiments: Experiments | null,
  translationKey: string,
  count?: number,
  context?: string,
) {
  if (experiments && experiments.enabled('specs.groups.UseICUKeysFormat')) {
    return `${translationKey}_icu`;
  }
  if (context) {
    return `${translationKey}_${context}`;
  }
  if (count === 0 || (count && count > 1)) {
    return `${translationKey}_plural`;
  }
  return translationKey;
}
