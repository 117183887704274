import React from 'react';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { getKey } from './helpers';

type PluralTextProps = {
  translationKey: string;
  count?: number;
  context?: string;
};

export const PluralText: React.FC<PluralTextProps> = ({
  translationKey,
  count,
  context,
}) => {
  const { experiments } = useExperiments();
  const { t } = useTranslation();

  return (
    <>
      {t(getKey(experiments, translationKey, count, context), {
        count,
      })}
    </>
  );
};
